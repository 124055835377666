import { FC, ReactNode, Suspense, lazy, useMemo } from "react";
import { HTTPAuthError, HTTPError } from "types/HTTPError";
import { MainRouteKeysType, SettingsKeysType } from "./SuperAdminDrawerLayout";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { UserContext, useUserReducer } from "@context/userContext";
import { getUser, getUserImage } from "@nexusAPI/users";
import { getUserID, isTokenExpired } from "@utils/authService";

import IonageNexusLoader from "../IonageNexusLoader";
import { getConnectorTypes } from "@nexusAPI/connector-types";
import { useQuery } from "@tanstack/react-query";
import useSuperAdminLogout from "./useSuperAdminLogout";

const TabsOrgSettingLayout = lazy(
  () => import("@components/layouts/tabsOrgSettingsLayout")
);
const EventsLogScreen = lazy(() => import("@screens/eventLogsScreen"));
const OcpiScreen = lazy(() => import("@screens/ocpiScreen"));

const ChargerTemplatesScreen = lazy(
  () => import("@screens/chargerTemplatesScreen")
);
const CatalogueLayout = lazy(() => import("./CatalogueLayout"));

const LogsScreen = lazy(() => import("@screens/logsScreen"));

const ProfileScreen = lazy(() => import("@screens/profileScreen"));
const TabsSettingLayout = lazy(
  () => import("@components/layouts/tabsSettingLayout")
);

const SuperAdminDrawerLayout = lazy(() => import("./SuperAdminDrawerLayout"));

const ManageUsersScreen = lazy(() => import("@screens/manageUsersScreen"));
const TenantsScreen = lazy(() => import("@screens/tenantsScreen"));
const BrandScreen = lazy(() => import("@screens/brandScreen"));
const NotFound = lazy(() => import("@screens/notFound"));

const SuperAdminProtectedRoute: FC = () => {
  const token = localStorage.getItem("token");
  const logout = useSuperAdminLogout();

  const TOKEN_EXPIRED = isTokenExpired();

  const [userState, userDispatch] = useUserReducer();

  const location = useLocation();

  const currentUserID = getUserID() ?? "";

  const userStateContext = useMemo(() => {
    return { userState, userDispatch };
  }, [userState, userDispatch]);

  const { refetch: refetchUserImage, isPending: currentUserImagePending } =
    useQuery({
      queryKey: ["currentUserImage", currentUserID],
      queryFn: async ({ signal }) => {
        const data = await getUserImage({
          params: { id: currentUserID },
          signal,
        });
        const { image } = data;
        userDispatch({
          type: "UPDATE_USER",
          payload: { image },
        });
        return data;
      },
      enabled: false,
    });

  const { refetch: refetchConnectorTypes, isPending: connectorTypesPending } =
    useQuery({
      queryKey: ["connectorTypes"],
      queryFn: async ({ signal }) => {
        const data = await getConnectorTypes({
          signal,
        });
        localStorage.setItem("Connectors", JSON.stringify(data));
        return data;
      },
      enabled: false,
    });

  const { isPending: currentUserPending } = useQuery({
    queryKey: ["currentUser", currentUserID],
    queryFn: async ({ signal }) => {
      try {
        const data = await getUser({
          params: { id: currentUserID },
          signal,
        });
        const { name, firstName, email, mobile } = data;
        //authenticated fetch all API's now
        void refetchUserImage();
        void refetchConnectorTypes();
        userDispatch({
          type: "UPDATE_USER",
          payload: { name, firstName, email, mobile },
        });
        return data;
      } catch (error: any) {
        const { status } = error as { status: HTTPAuthError | HTTPError };
        switch (status) {
          case HTTPAuthError.BAD_REQUEST:
          case HTTPAuthError.UNAUTHORIZED:
          case HTTPAuthError.FORBIDDEN:
          case HTTPError.USER_ACCOUNT_CHANGED:
          case HTTPError.TENANT_COMPONENT_CHANGED:
            logout();
            break;
        }
        throw error;
      }
    },
    enabled: !TOKEN_EXPIRED && !!currentUserID,
  });

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace={true} />;
  }
  if (TOKEN_EXPIRED) {
    logout();
  }

  if (currentUserImagePending || connectorTypesPending || currentUserPending) {
    return <IonageNexusLoader />;
  }

  const mainRoutes = new Map<MainRouteKeysType, { element?: ReactNode }>();
  mainRoutes.set("tenants", {
    element: <TenantsScreen />,
  });
  mainRoutes.set("catalogue", {
    element: <CatalogueLayout />,
  });
  mainRoutes.set("ocpi", {
    element: <OcpiScreen />,
  });
  mainRoutes.set("logs", {
    element: <LogsScreen />,
  });
  mainRoutes.set("events-log", { element: <EventsLogScreen /> });

  const userSettingsRoutes = new Map<string, { element?: ReactNode }>();
  userSettingsRoutes.set("profile", {
    element: <ProfileScreen />,
  });

  const tenantSettingsRoutes = new Map<string, { element?: ReactNode }>();
  tenantSettingsRoutes.set("manage-users", {
    element: <ManageUsersScreen />,
  });

  return (
    <UserContext value={userStateContext}>
      <Suspense fallback={<IonageNexusLoader />}>
        <Routes>
          <Route
            element={
              <SuperAdminDrawerLayout
                settingsKeys={
                  [
                    ...(userSettingsRoutes.size ? ["user-settings"] : []),
                    ...(tenantSettingsRoutes.size ? ["tenant-settings"] : []),
                  ] as SettingsKeysType[]
                }
                mainRouteKeys={[...mainRoutes.keys()]}
              />
            }
          >
            <Route
              index
              element={
                <Navigate
                  to={`./${mainRoutes.keys().next().value ?? "not-found"}`}
                />
              }
            />
            {[...mainRoutes.entries()].map(([key, { element }]) => {
              if (key === "catalogue") {
                return (
                  <Route key={key} path={key} element={element}>
                    <Route index element={<Navigate to={`./vendors`} />} />
                    <Route
                      path={"models"}
                      element={<ChargerTemplatesScreen />}
                    />
                    <Route path={"vendors"} element={<BrandScreen />} />
                    <Route path="*" element={<NotFound />} />
                  </Route>
                );
              }
              return <Route key={key} path={key} element={element} />;
            })}
            <Route path="user-settings" element={<TabsSettingLayout />}>
              <Route
                index
                element={
                  <Navigate
                    to={`./${userSettingsRoutes.keys().next().value ?? "not-found"}`}
                  />
                }
              />
              {[...userSettingsRoutes.entries()].map(([key, { element }]) => (
                <Route key={key} path={key} element={element} />
              ))}
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="tenant-settings" element={<TabsOrgSettingLayout />}>
              <Route
                index
                element={
                  <Navigate
                    to={`./${tenantSettingsRoutes.keys().next().value ?? "not-found"}`}
                  />
                }
              />
              {[...tenantSettingsRoutes.entries()].map(([key, { element }]) => (
                <Route key={key} path={key} element={element} />
              ))}
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </UserContext>
  );
};

export default SuperAdminProtectedRoute;
