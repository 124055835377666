import { ApiEndpoints, RESTServerRoute } from "types/Server";

import { nexusAPI } from "@utils/APIInterceptor";

async function getConnectorTypes({ signal }: { signal?: AbortSignal }) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const response = await nexusAPI(token).get<
      Array<{ type: string; display_text: string; type_url: string }>
    >(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_CHARGING_STATION_CONNECTORS}`,
      { signal }
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw error;
  }
}

export { getConnectorTypes };
