import { FC } from "react";
import IonageNexusLoader from "./IonageNexusLoader";
import NexusRoutes from "./nexus/NexusRoutes";
import NotFound from "@screens/notFound";
import OCPIRoutes from "./ocpi/OCPIRoutes";
import SuperAdminRoutes from "./superAdmin/SuperAdminRoutes";
import { getTenantbySubdomain } from "@nexusAPI/tenants";
import { useQuery } from "@tanstack/react-query";

const SUPER_ADMIN_SUBDOMAIN = "nexus";

const Routes: FC = () => {
  const HOSTNAME = window.location.hostname;
  const [provider_name] = HOSTNAME.split(".");
  const IS_SUPER_ADMIN = provider_name === SUPER_ADMIN_SUBDOMAIN;

  const {
    data: subdomainData,
    error,
    isPending,
    isError,
  } = useQuery({
    queryKey: ["subdomain", provider_name],
    queryFn: async ({ signal }) => {
      const data = await getTenantbySubdomain({
        params: { Subdomain: provider_name },
        signal,
      });
      return data;
    },
    enabled: !IS_SUPER_ADMIN, // Query only when subdomain is available
  });

  if (IS_SUPER_ADMIN) {
    return <SuperAdminRoutes />;
  }

  if (isPending) {
    return <IonageNexusLoader />;
  }

  if (isError && (error as any)?.status === 404) {
    return (
      <NotFound disableNavigation={true} message="Operator does not exist." />
    );
  }

  if (subdomainData && subdomainData.isExternal) {
    return <OCPIRoutes subdomainData={subdomainData} />;
  }

  return <NexusRoutes subdomainData={subdomainData} />;
};

export default Routes;
