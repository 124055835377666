import { nexusDB } from "@utils/nexusDB";
import { useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";

const useNexusLogout = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("dateSelectionHome");
    localStorage.removeItem("Connectors");
    queryClient.clear();
    nexusDB
      .delete()
      .then(() => {
        if (process.env.NODE_ENV === "development") {
          console.log("nexusDB successfully deleted");
        }
      })
      .catch((err: unknown) => {
        if (process.env.NODE_ENV === "development") {
          console.error("Could not delete database", err);
        }
      })
      .finally(() => {
        nexusDB.open().catch((err: unknown) => {
          if (process.env.NODE_ENV === "development") {
            console.error("Failed to open db: ", err);
          }
        });
      });
    void navigate("/login", { replace: true });
  };

  return logout;
};

export default useNexusLogout;
