import "@fontsource/dm-sans/latin-400.css";
import "@fontsource/dm-sans/latin-500.css";
import "@fontsource/dm-sans/latin-700.css";
import "@fontsource/dm-mono/latin-400.css";

import { CssBaseline, ThemeProvider, styled } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import ErrorBoundary from "@components/errorBoundary";
import { FC } from "react";
import { RootTheme } from "@config/theme/rootTheme";
import Routes from "@config/routes/Routes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

const MainContainer = styled("div")({
  minHeight: "100vh",
  display: "flex",
});

const App: FC = () => {
  return (
    <ThemeProvider theme={RootTheme}>
      <CssBaseline />
      <MainContainer>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <Routes />
          </QueryClientProvider>
        </ErrorBoundary>
      </MainContainer>
    </ThemeProvider>
  );
};

export default App;
