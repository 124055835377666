import { BrowserRouter, Route, Routes } from "react-router";
import { Suspense, lazy } from "react";

import IonageNexusLoader from "../IonageNexusLoader";
import SuperAdminProtectedRoute from "./SuperAdminProtectedRoute";

const LoginScreen = lazy(() => import("@screens/loginScreen"));

const NotFound = lazy(() => import("@screens/notFound"));

const SuperAdminRoutes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<IonageNexusLoader />}>
        <Routes>
          <Route path="/*" element={<SuperAdminProtectedRoute />} />
          <Route
            path="login"
            element={
              <Suspense fallback={<IonageNexusLoader />}>
                <LoginScreen />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<IonageNexusLoader />}>
                <NotFound />
              </Suspense>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default SuperAdminRoutes;
