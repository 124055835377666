import { type IconButtonProps, IconButton as MIconButton } from "@mui/material";

import { memo } from "react";

export type IonIconButtonType = IconButtonProps;

const IonIconButton = memo(
  ({ children, ...buttonProps }: IonIconButtonType) => {
    return <MIconButton {...buttonProps}>{children}</MIconButton>;
  }
);

export { IonIconButton };
