import { FC, ReactNode, Suspense, lazy, useMemo } from "react";
import { HTTPAuthError, HTTPError } from "types/HTTPError";
import { MainRouteKeysType, SettingsKeysType } from "./OCPIDrawerLayout";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { TenantContext, useTenantReducer } from "@context/tenantContext";
import { UserContext, useUserReducer } from "@context/userContext";
import { getTenantID, getUserID, isTokenExpired } from "@utils/authService";
import { getUser, getUserImage } from "@nexusAPI/users";

import IonageNexusLoader from "../IonageNexusLoader";
import { TenantSubdomain } from "types/Tenant";
import { getTenantByID } from "@nexusAPI/tenants";
import useOCPILogout from "./useOCPILogout";
import { useQuery } from "@tanstack/react-query";

const OcpiStationsScreen = lazy(
  () => import("@screens/ocpiDashboardScreens/stationsScreen")
);
const OcpiRevenueScreen = lazy(
  () => import("@screens/ocpiDashboardScreens/revenueScreen")
);

const OCPIDrawerLayout = lazy(() => import("./OCPIDrawerLayout"));

const ProfileScreen = lazy(() => import("@screens/profileScreen"));
const TabsSettingLayout = lazy(
  () => import("@components/layouts/tabsSettingLayout")
);
const TabsOrgSettingLayout = lazy(
  () => import("@components/layouts/tabsOrgSettingsLayout")
);
const OcpiSessionsScreen = lazy(
  () => import("@screens/ocpiDashboardScreens/sessionsScreen")
);

const OcpiTariffScreen = lazy(
  () => import("@screens/ocpiDashboardScreens/tariffScreen")
);
const ManageUsersScreen = lazy(() => import("@screens/manageUsersScreen"));
const BusinessScreen = lazy(
  () => import("@screens/ocpiDashboardScreens/businessScreen")
);
const NotFound = lazy(() => import("@screens/notFound"));

interface OCPIProtectedRouteProps {
  subdomainData?: TenantSubdomain;
}

const OCPIProtectedRoute: FC<OCPIProtectedRouteProps> = ({ subdomainData }) => {
  const token = localStorage.getItem("token");
  const logout = useOCPILogout();

  const TOKEN_EXPIRED = isTokenExpired();

  const [userState, userDispatch] = useUserReducer();
  const [tenantState, tenantDispatch] = useTenantReducer();

  const location = useLocation();

  const currentTenantID = getTenantID() ?? "";
  const currentUserID = getUserID() ?? "";

  const tenantStateContext = useMemo(() => {
    return { tenantState, tenantDispatch };
  }, [tenantState, tenantDispatch]);

  const userStateContext = useMemo(() => {
    return { userState, userDispatch };
  }, [userState, userDispatch]);

  const { isPending: currentTenantPending, refetch: refetchTenant } = useQuery({
    queryKey: ["currentTenant", currentTenantID, subdomainData],
    queryFn: async ({ signal }) => {
      const data = await getTenantByID({
        params: { id: currentTenantID },
        signal,
      });
      const { components } = data;
      tenantDispatch({
        type: "UPDATE_TENANT",
        payload: { ...subdomainData, components },
      });
      return data;
    },
    enabled: false,
  });

  const { refetch: refetchUserImage, isPending: currentUserImagePending } =
    useQuery({
      queryKey: ["currentUserImage", currentUserID],
      queryFn: async ({ signal }) => {
        const data = await getUserImage({
          params: { id: currentUserID },
          signal,
        });
        const { image } = data;
        userDispatch({
          type: "UPDATE_USER",
          payload: { image },
        });
        return data;
      },
      enabled: false,
    });

  const { isPending: currentUserPending } = useQuery({
    queryKey: ["currentUser", currentUserID],
    queryFn: async ({ signal }) => {
      try {
        const data = await getUser({
          params: { id: currentUserID },
          signal,
        });
        const { name, firstName, email, mobile } = data;
        //authenticated fetch all API's now
        void refetchTenant();
        void refetchUserImage();
        userDispatch({
          type: "UPDATE_USER",
          payload: { name, firstName, email, mobile },
        });
        return data;
      } catch (error: any) {
        const { status } = error as { status: HTTPAuthError | HTTPError };
        switch (status) {
          case HTTPAuthError.BAD_REQUEST:
          case HTTPAuthError.UNAUTHORIZED:
          case HTTPAuthError.FORBIDDEN:
          case HTTPError.USER_ACCOUNT_CHANGED:
          case HTTPError.TENANT_COMPONENT_CHANGED:
            logout();
            break;
        }
        throw error;
      }
    },
    enabled: !TOKEN_EXPIRED && !!currentUserID,
  });

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace={true} />;
  }
  if (TOKEN_EXPIRED) {
    logout();
  }

  if (currentTenantPending || currentUserImagePending || currentUserPending) {
    return <IonageNexusLoader />;
  }

  const mainRoutes = new Map<MainRouteKeysType, { element?: ReactNode }>();
  mainRoutes.set("stations", {
    element: <OcpiStationsScreen />,
  });
  mainRoutes.set("tariffs", {
    element: <OcpiTariffScreen />,
  });
  mainRoutes.set("sessions", {
    element: <OcpiSessionsScreen />,
  });
  mainRoutes.set("revenue", { element: <OcpiRevenueScreen /> });

  const userSettingsRoutes = new Map<string, { element?: ReactNode }>();
  userSettingsRoutes.set("profile", {
    element: <ProfileScreen />,
  });

  const tenantSettingsRoutes = new Map<string, { element?: ReactNode }>();
  tenantSettingsRoutes.set("business", {
    element: <BusinessScreen />,
  });
  tenantSettingsRoutes.set("manage-users", {
    element: <ManageUsersScreen />,
  });

  return (
    <TenantContext value={tenantStateContext}>
      <UserContext value={userStateContext}>
        <Suspense fallback={<IonageNexusLoader />}>
          <Routes>
            <Route
              element={
                <OCPIDrawerLayout
                  settingsKeys={
                    [
                      ...(userSettingsRoutes.size ? ["user-settings"] : []),
                      ...(tenantSettingsRoutes.size ? ["tenant-settings"] : []),
                    ] as SettingsKeysType[]
                  }
                  mainRouteKeys={[...mainRoutes.keys()]}
                />
              }
            >
              <Route
                index
                element={
                  <Navigate
                    to={`./${mainRoutes.keys().next().value ?? "not-found"}`}
                  />
                }
              />
              {[...mainRoutes.entries()].map(([key, { element }]) => {
                return <Route key={key} path={key} element={element} />;
              })}
              <Route path="user-settings" element={<TabsSettingLayout />}>
                <Route
                  index
                  element={
                    <Navigate
                      to={`./${userSettingsRoutes.keys().next().value ?? "not-found"}`}
                    />
                  }
                />
                {[...userSettingsRoutes.entries()].map(([key, { element }]) => (
                  <Route key={key} path={key} element={element} />
                ))}
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route path="tenant-settings" element={<TabsOrgSettingLayout />}>
                <Route
                  index
                  element={
                    <Navigate
                      to={`./${tenantSettingsRoutes.keys().next().value ?? "not-found"}`}
                    />
                  }
                />
                {[...tenantSettingsRoutes.entries()].map(
                  ([key, { element }]) => (
                    <Route key={key} path={key} element={element} />
                  )
                )}
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Suspense>
      </UserContext>
    </TenantContext>
  );
};

export default OCPIProtectedRoute;
