import { ActionResponse, DataResult, UserDataResult } from "types/DataResult";
import { ApiEndpoints, RESTServerRoute } from "types/Server";
import { User, UserTokenSites } from "types/User";
import { UserSitesItemType, nexusDB } from "@utils/nexusDB";

import Dexie from "dexie";
import { HTTPError } from "types/HTTPError";
import { SiteUser } from "types/Site";
import { nexusAPI } from "@utils/APIInterceptor";

async function getUsers(payload: {
  Issuer?: boolean;
  Limit?: number;
  SortFields?: string;
  Skip?: number;
  Search?: string;
}) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const response = await nexusAPI(token).get<UserDataResult>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_USERS}`,
      {
        params: payload,
      }
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw error;
  }
}

async function getUser({
  params,
  signal,
}: {
  params: { id: string };
  signal?: AbortSignal;
}) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { id } = params || {};
    if (!id) {
      throw new Error("Id not found");
    }
    const response = await nexusAPI(token).get<User>(
      `${ApiEndpoints.REST_SECURED_API}/users/${id}`,
      { signal }
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    const { status } = error || {};
    switch (status) {
      case HTTPError.OBJECT_DOES_NOT_EXIST_ERROR:
        throw new Error("User does not exist");
    }
    throw error;
  }
}

async function getUserImage({
  params,
  signal,
}: {
  params: { id: string };
  signal?: AbortSignal;
}) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { id } = params || {};
    if (!id) {
      throw new Error("Id not found");
    }
    const response = await nexusAPI(token).get<{
      id: string;
      image: string;
    }>(`${ApiEndpoints.REST_SECURED_API}/users/${id}/image`, {
      signal,
    });
    const { data } = response || {};
    return data;
  } catch (error: any) {
    const { status } = error || {};
    switch (status) {
      case HTTPError.OBJECT_DOES_NOT_EXIST_ERROR:
        throw new Error("User does not exist");
    }
    throw error;
  }
}

async function updateUser({
  params,
  body,
}: {
  params: { id: string };
  body: Partial<User>;
}) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { id } = params || {};
    if (!id) {
      throw new Error("Id not found");
    }
    const response = await nexusAPI(token).put<ActionResponse>(
      `${ApiEndpoints.REST_SECURED_API}/users/${id}`,
      body
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    const { status } = error || {};
    switch (status) {
      case HTTPError.USER_EMAIL_ALREADY_EXIST_ERROR:
        throw new Error("Email already exist");
      case HTTPError.OBJECT_DOES_NOT_EXIST_ERROR:
        throw new Error("User does not exist");
    }
    throw error;
  }
}

async function createUser(body: Partial<User>) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const response = await nexusAPI(token).post<ActionResponse>(
      `${ApiEndpoints.REST_SECURED_API}/users`,
      body
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    const { status } = error || {};
    switch (status) {
      case HTTPError.USER_EMAIL_ALREADY_EXIST_ERROR:
        throw new Error("Email already exist");
      case HTTPError.OBJECT_DOES_NOT_EXIST_ERROR:
        throw new Error("User does not exist");
    }
    throw error;
  }
}

async function deleteUser(payload: { id: string }) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { id } = payload || {};
    if (!id) {
      throw new Error("Id not found");
    }
    const response = await nexusAPI(token).delete<ActionResponse>(
      `${ApiEndpoints.REST_SECURED_API}/users/${id}`
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    const { status } = error || {};
    switch (status) {
      case HTTPError.OBJECT_DOES_NOT_EXIST_ERROR:
        throw new Error("User does not exist");
    }
    throw error;
  }
}

async function storeUserTokenSites(data: UserTokenSites) {
  try {
    const DATA: Array<UserSitesItemType> = [];
    for (const siteID in data) {
      const { admin, owner } = data[siteID] || {};
      DATA.push({
        id: siteID,
        admin: admin ? 1 : 0,
        owner: owner ? 1 : 0,
      });
    }

    //Clear User Site Logic
    await nexusDB.userSites
      .count()
      .then((COUNT) => {
        if (COUNT > 0) {
          nexusDB.userSites
            .clear()
            .then(() => {
              if (process.env.NODE_ENV === "development") {
                console.log("userSites cleared");
              }
            })
            .catch((error) => {
              if (process.env.NODE_ENV === "development") {
                console.log("userSites clear failed", error);
              }
            });
        }
      })
      .catch();

    const lastKey = await nexusDB.userSites.bulkPut(DATA);
    if (process.env.NODE_ENV === "development") {
      console.log("Last userSites's id was:", lastKey);
    }
    return true;
  } catch (error) {
    if (error instanceof Dexie.BulkError) {
      if (process.env.NODE_ENV === "development") {
        console.log(error.failures.length + " stations failed to be added");
      }
    }
    return false;
  }
}

async function getUserTokenSites({ signal }: { signal: AbortSignal }) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const response = await nexusAPI(token).get<UserTokenSites>(
      `${ApiEndpoints.REST_SECURED_API}/user/sites`,
      { signal }
    );
    const { data } = response || {};
    await storeUserTokenSites(data);
    return data;
  } catch (error: any) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw error;
  }
}

async function getUserSites(payload: {
  userID: string;
  Limit?: number;
  Skip?: number;
  SortFields?: string;
}) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { userID } = payload;
    if (!userID) {
      throw new Error("UserID not found");
    }
    const response = await nexusAPI(token).get<DataResult<SiteUser>>(
      `${ApiEndpoints.REST_SECURED_API}/users/${userID}/sites`,
      { params: payload }
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw error;
  }
}

async function addSitesToUser(
  payload: { userID: string },
  body: { siteIDs: string[] }
) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { userID } = payload;
    if (!userID) {
      throw new Error("UserID not found");
    }
    const response = await nexusAPI(token).post<ActionResponse>(
      `${ApiEndpoints.REST_SECURED_API}/users/${userID}/sites`,
      body
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    const { status } = error || {};
    switch (status) {
      case HTTPError.USER_EMAIL_ALREADY_EXIST_ERROR:
        throw new Error("Email already exist");
      case HTTPError.OBJECT_DOES_NOT_EXIST_ERROR:
        throw new Error("User does not exist");
    }
    throw error;
  }
}

async function removeSitesFromUser(
  payload: { userID: string },
  body: {
    siteIDs: string[];
  }
) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { userID } = payload;
    if (!userID) {
      throw new Error("UserID not found");
    }
    const response = await nexusAPI(token).put<ActionResponse>(
      `${ApiEndpoints.REST_SECURED_API}/users/${userID}/sites`,
      body
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    const { status } = error || {};
    switch (status) {
      case HTTPError.USER_EMAIL_ALREADY_EXIST_ERROR:
        throw new Error("Email already exist");
      case HTTPError.OBJECT_DOES_NOT_EXIST_ERROR:
        throw new Error("User does not exist");
    }
    throw error;
  }
}

export {
  getUser,
  getUserImage,
  getUsers,
  getUserTokenSites,
  updateUser,
  createUser,
  deleteUser,
  getUserSites,
  addSitesToUser,
  removeSitesFromUser,
};
