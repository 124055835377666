import { BrowserRouter, Route, Routes } from "react-router";
import { FC, Suspense, lazy } from "react";

import IonageNexusLoader from "../IonageNexusLoader";
import NexusProtectedRoute from "./NexusProtectedRoute";
import { TenantSubdomain } from "types/Tenant";

const LoginScreen = lazy(() => import("@screens/loginScreen"));
const NotFound = lazy(() => import("@screens/notFound"));

interface NexusRoutesProps {
  subdomainData?: TenantSubdomain;
}

const NexusRoutes: FC<NexusRoutesProps> = ({ subdomainData }) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/*"
          element={<NexusProtectedRoute subdomainData={subdomainData} />}
        />
        <Route
          path="login"
          element={
            <Suspense fallback={<IonageNexusLoader />}>
              <LoginScreen subdomainData={subdomainData} />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<IonageNexusLoader />}>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default NexusRoutes;
