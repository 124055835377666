import { IonButton, type IonButtonType } from "./IonButton";
import { memo, useState } from "react";

export type IonButtonMultiVariantType = IonButtonType;

const IonButtonMultiVariant = memo(
  ({ children, ...buttonProps }: IonButtonMultiVariantType) => {
    const [buttonHovered, setbuttonHovered] = useState<boolean>(false);
    return (
      <IonButton
        {...buttonProps}
        variant={buttonHovered ? "contained" : "outlined"}
        onMouseEnter={(event) => {
          setbuttonHovered(true);
          if (buttonProps?.onMouseEnter) {
            buttonProps.onMouseEnter(event);
          }
        }}
        onMouseLeave={(event) => {
          setbuttonHovered(false);
          if (buttonProps?.onMouseLeave) {
            buttonProps.onMouseLeave(event);
          }
        }}
      >
        {children}
      </IonButton>
    );
  }
);

export { IonButtonMultiVariant };
