import { BrowserRouter, Route, Routes } from "react-router";
import { FC, Suspense, lazy } from "react";

import IonageNexusLoader from "../IonageNexusLoader";
import OCPIProtectedRoute from "./OCPIProtectedRoute";
import { TenantSubdomain } from "types/Tenant";

const OcpiLoginScreen = lazy(
  () => import("@screens/ocpiDashboardScreens/loginScreen")
);

const NotFound = lazy(() => import("@screens/notFound"));

interface OCPIRoutesProps {
  subdomainData?: TenantSubdomain;
}

const OCPIRoutes: FC<OCPIRoutesProps> = ({ subdomainData }) => {
  return (
    <BrowserRouter>
      <Suspense fallback={<IonageNexusLoader />}>
        <Routes>
          <Route
            path="/*"
            element={<OCPIProtectedRoute subdomainData={subdomainData} />}
          />
          <Route
            path="login"
            element={
              <Suspense fallback={<IonageNexusLoader />}>
                <OcpiLoginScreen subdomainData={subdomainData} />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<IonageNexusLoader />}>
                <NotFound />
              </Suspense>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default OCPIRoutes;
