import { type ButtonProps, Button as MButton, styled } from "@mui/material";

import { memo } from "react";

export type IonButtonType = ButtonProps;

const DEFAULT_COLOR = "primary";
const DEFAULT_SIZE = "medium";

const MStyledButton = styled(MButton)<IonButtonType>(({ theme }) => ({
  borderRadius: Number(theme.shape.borderRadius) * 2,
  textTransform: "none",
  boxShadow: theme.shadows[0],
  "&:hover": {
    boxShadow: theme.shadows[1],
  },
  "&:active": {
    boxShadow: theme.shadows[1],
  },
}));

const IonButton = memo(
  ({
    children,
    color = DEFAULT_COLOR,
    size = DEFAULT_SIZE,
    ...buttonProps
  }: IonButtonType) => {
    return (
      <MStyledButton color={color} size={size} {...buttonProps}>
        {children}
      </MStyledButton>
    );
  }
);

export { IonButton };
