import type { AxiosError, AxiosProgressEvent, AxiosResponse } from "axios";

import axios from "axios";

export type IonProgressEvent = AxiosProgressEvent;

const commonConfig = {
  timeout: 5 * 60 * 1000,
  headers: {
    "Content-Type": "application/json",
  },
};

export type ApiErrorType = AxiosResponse<
  | {
      message?: string;
      error?: string;
      errors?: Record<string, string[]>;
    }
  | string
>;

export const nexusAPI = (token: string = "") => {
  const protocol = import.meta.env.VITE_API_PROTOCOL;
  const host = import.meta.env.VITE_API_HOST;
  const port = import.meta.env.VITE_API_PORT;
  const BASE_URL = `${protocol}://${host}${port ? `:${port}` : ""}`;

  const nexusInstance = axios.create({
    ...commonConfig,
    baseURL: BASE_URL,
  });

  nexusInstance.interceptors.request.use(
    (config) => {
      if (token && config.headers) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  nexusInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      const { response, request } = error || {};
      return Promise.reject(response || request);
    }
  );
  return nexusInstance;
};

export const ionageAPI = (token: string = "") => {
  const ionageInstance = axios.create({
    ...commonConfig,
    baseURL: import.meta.env.VITE_IONAGE_SERVICE_API,
  });

  ionageInstance.interceptors.request.use(
    (config) => {
      if (token && config.headers) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  ionageInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      const { response, request } = error || {};
      return Promise.reject(response || request);
    }
  );
  return ionageInstance;
};

export const externalAPI = () => {
  const externalInstance = axios.create({
    ...commonConfig,
    baseURL: "",
  });
  externalInstance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  externalInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      const { response, request } = error || {};
      return Promise.reject(response || request);
    }
  );
  return externalInstance;
};

export const logError = (message: string, errorheader: string = "") => {
  if (process.env.NODE_ENV === "development") {
    console.log("ERROR", errorheader, message);
  }
};

export const getErrorMessage = (
  error: ApiErrorType | Error | string,
  errorheader: string = ""
): string => {
  let errorMessage = "Something went wrong";
  if (typeof error === "string") {
    errorMessage = error;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (error?.data) {
    const { data } = error;
    if (typeof data === "string") {
      errorMessage = data;
    } else {
      const {
        error: apiError = "",
        message: apiMessage = "",
        errors: apiErrors,
      } = data;
      errorMessage =
        apiError ||
        apiMessage ||
        (apiErrors && JSON.stringify(apiErrors)) ||
        errorMessage;
    }
  }
  logError(errorMessage, errorheader);
  return errorMessage;
};
