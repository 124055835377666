import React, { createContext, useContext, useReducer } from "react";

import { TenantComponent } from "types/Tenant";

interface TenantContextState {
  name: string;
  logo: string;
  type: string;
  isExternal: boolean;
  components?: TenantComponent;
}

const tenantInitialState: TenantContextState = {
  name: "",
  logo: "",
  type: "",
  isExternal: false,
};

type Action = { type: "UPDATE_TENANT"; payload: Partial<TenantContextState> };

const tenantReducer = (
  state: TenantContextState,
  action: Action
): TenantContextState => {
  switch (action.type) {
    case "UPDATE_TENANT":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const TenantContext = createContext<{
  tenantState: TenantContextState;
  tenantDispatch: React.Dispatch<Action>;
}>({
  tenantState: tenantInitialState,
  tenantDispatch: () => undefined,
});

const useTenantContext = () => useContext(TenantContext);
const useTenantReducer = () => useReducer(tenantReducer, tenantInitialState);

export { TenantContext, useTenantContext, useTenantReducer };
export type { TenantContextState };
