import React, { createContext, useContext, useReducer } from "react";

interface UserContextState {
  firstName: string;
  name: string;
  email: string;
  mobile: string;
  image: string;
}

const userInitialState: UserContextState = {
  firstName: "",
  name: "",
  email: "",
  mobile: "",
  image: "",
};

type Action = { type: "UPDATE_USER"; payload: Partial<UserContextState> };

const userReducer = (
  state: UserContextState,
  action: Action
): UserContextState => {
  switch (action.type) {
    case "UPDATE_USER":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const UserContext = createContext<{
  userState: UserContextState;
  userDispatch: React.Dispatch<Action>;
}>({
  userState: userInitialState,
  userDispatch: () => undefined,
});

const useUserContext = () => useContext(UserContext);
const useUserReducer = () => useReducer(userReducer, userInitialState);

export { UserContext, useUserContext, useUserReducer };
export type { UserContextState };
