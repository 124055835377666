import { useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";

const useSuperAdminLogout = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("Connectors");
    queryClient.clear();
    void navigate("/login", { replace: true });
  };

  return logout;
};

export default useSuperAdminLogout;
