import { ActionResponse, TenantDataResult } from "types/DataResult";
import { ApiEndpoints, RESTServerRoute } from "types/Server";
import {
  Tenant,
  TenantBusinessDetails,
  TenantSubdomain,
  UpdateTenantBusinessPayload,
} from "types/Tenant";

import { getTenantID } from "@utils/authService";
import { nexusAPI } from "@utils/APIInterceptor";

export interface NewTenant {
  logo: string;
  id: string;
  name: string;
  email: string;
  subdomain: string;
  phoneNumber: string;
  type: string;
  channelPartner: string;
  channelPartnerId: number;
  businessName: string;
  businessAddress: string;
  gstin: string;
  cin: string;
  flatFee: number | null;
  energy: number | null;
  tax: number | null;
  logoType: string;
  logoHeight: number;
  logoWidth: number;
  bannerLogo: string;
  website: string;
  partyID: string;
  platformFee: { type: string; rate: number | null };
}

async function getTenants(payload: {
  Issuer?: boolean;
  Limit?: number;
  Skip?: number;
  SortFields?: string;
  WithLogo?: boolean;
  isExternal?: boolean;
}) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const response = await nexusAPI(token).get<TenantDataResult>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_TENANTS}`,
      {
        params: payload,
      }
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw error;
  }
}

async function postTenant(body: {
  // issuer: boolean;
  // id: string;
  // name: string;
  // address: {
  //   address1: string;
  //   address2: string;
  //   postalCode: number;
  //   city: string;
  //   department: string;
  //   country: string;
  //   region: string;
  //   coordinates: number[];
  // };
  // logo: string;

  // lastChangedOn: string;
  name: string;
  email: string;
  subdomain: string;
  phoneNumber: string;
  type: string;
  channelPartner: string;
  channelPartnerId: number | null;
  businessName: string;
  businessAddress: string;
  gstin: string | null;
  flatFee: number | null;
  energy: number | null;
  tax: number | null;
  logo: string;
  logoType: string;
  logoHeight: number;
  logoWidth: number;
  bannerLogo: string;
  website: string;
  partyID: string;
  platformFee: { type: string; rate: number | null };
}) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }

    const response = await nexusAPI(token).post(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_NEW_TENANT}`,
      body
    );

    const { data } = response || {};
    return data;
  } catch (error: any) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw error;
  }
}

async function getTenantByID({
  params,
  signal,
}: {
  params: { id: string };
  signal?: AbortSignal;
}) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { id, ...restParams } = params || {};
    if (!id) {
      throw new Error("TenantID not found");
    }
    const response = await nexusAPI(token).get<Tenant>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_TENANTS}/${id}`,
      {
        params: restParams,
        signal,
      }
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw error;
  }
}

async function deleteTenant(payload?: { id?: string }) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { id } = payload || {};
    if (!id) {
      throw new Error("Company Id not found");
    }
    const response = await nexusAPI(token).delete<TenantDataResult>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_TENANTS}/${id}`
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw error;
  }
}

async function getTenantLogo() {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const tenantID = getTenantID();
    if (!tenantID) {
      throw new Error("tenantID not found");
    }
    const response = await nexusAPI(token).get<Blob>(
      `${ApiEndpoints.REST_UTIL_API}/${RESTServerRoute.REST_TENANT_LOGO}`,
      {
        responseType: "blob",
        params: { ID: tenantID },
      }
    );
    const { data } = response || {};
    return URL.createObjectURL(data);
  } catch (error: any) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw error;
  }
}

async function getTenantLogobySubdomain() {
  try {
    const HOSTNAME = window.location.hostname || "";
    const [provider_name] = HOSTNAME?.split(".") || "";
    if (!provider_name || provider_name === "localhost") {
      throw new Error("No subdomain name found");
    }
    const response = await nexusAPI().get<Blob>(
      `${ApiEndpoints.REST_UTIL_API}/${RESTServerRoute.REST_TENANT_LOGO}`,
      {
        responseType: "blob",
        params: { Subdomain: provider_name },
      }
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw error;
  }
}

async function getTenantbySubdomain({
  params,
  signal,
}: {
  params: { Subdomain: string };
  signal: AbortSignal;
}) {
  const response = await nexusAPI().get<TenantSubdomain>(
    `${ApiEndpoints.REST_UTIL_API}/${RESTServerRoute.REST_TENANT_SUBDOMAIN}`,
    {
      params,
      signal,
    }
  );
  const { data } = response || {};
  return data;
}

async function getTenantBusinessDetails() {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const response = await nexusAPI(token).get<TenantBusinessDetails>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_TENANT_SETTINGS}`
    );
    return response.data;
  } catch (error: any) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw error;
  }
}

async function updateTenantBusinessDetails(
  payload: UpdateTenantBusinessPayload
) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const response = await nexusAPI(token).put<ActionResponse>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_TENANT_SETTINGS}`,
      payload
    );
    return response.data;
  } catch (error: any) {
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw error;
  }
}

export {
  getTenants,
  getTenantByID,
  postTenant,
  deleteTenant,
  getTenantLogo,
  getTenantLogobySubdomain,
  getTenantbySubdomain,
  getTenantBusinessDetails,
  updateTenantBusinessDetails,
};
