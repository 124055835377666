import { Box, Container, Link as LinkMui, Typography } from "@mui/material";
import React, { ErrorInfo, ReactNode } from "react";

import { IonButton } from "@components/ionButton";
import IonageLogoFull from "@assets/images/ionage-logo-full.svg?react";
import bgImage from "@assets/images/not-found-bg.svg";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log({ error, errorInfo });
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log the error to an error reporting service
    // crashlytics().recordError(error);
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;

    if (errorInfo) {
      return (
        <Box
          sx={{
            flex: 1,
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom right",
          }}
        >
          <Container
            disableGutters={true}
            component="main"
            maxWidth="lg"
            sx={{
              outline: "none",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <IonageLogoFull
              height={44}
              style={{
                margin: "1.5rem",
                marginLeft: "3rem",
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                p: 2,
                mt: 4,
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontSize: 34, fontWeight: 500, marginBottom: 2 }}
                align="center"
              >
                Oops, Something Went Wrong.
              </Typography>
              <Typography
                component="p"
                sx={{ fontWeight: 500, maxWidth: 768 }}
                align="center"
              >
                The app ran into a problem and could not continue. We apologise
                for any inconvenience this has caused! Please contact us if this
                issue persists.
              </Typography>
              {process.env.NODE_ENV === "development" && (
                <Box
                  sx={{
                    mt: 4,
                    p: 2,
                    borderRadius: 2,
                    height: "400px",
                    overflowY: "auto",
                    boxShadow: (theme) => theme.shadows[1],
                    background: (theme) => theme.palette.grey[200],
                  }}
                >
                  <Typography fontSize={24} fontWeight={500}>
                    {error && error.toString()}
                  </Typography>
                  <Typography fontSize={14}>
                    <pre>{errorInfo.componentStack}</pre>
                  </Typography>
                </Box>
              )}
              <LinkMui href="/" underline="none">
                <IonButton
                  variant="outlined"
                  fullWidth
                  color="button"
                  sx={{
                    height: 48,
                    mt: 2,
                    px: 4,
                  }}
                  aria-label="Discard"
                >
                  {"Go to home"}
                </IonButton>
              </LinkMui>
            </Box>
          </Container>
        </Box>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
