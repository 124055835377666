import { IonColors, IonCommonColors } from "./IonColors";

import { IonShadows } from "./IonShadows";
import { SimplePaletteColorOptions } from "@mui/material";
// import { PaletteColor, PaletteColorOptions } from "@mui/material";
import { ThemeOptions } from "@mui/material/styles/createTheme";

const customPalette: { [key: string]: SimplePaletteColorOptions } = {
  tertiary: {
    main: IonColors.ionDiamond[100],
    contrastText: IonCommonColors.black,
  },
  field: {
    main: IonColors.ionMidnightGreen[900],
    contrastText: IonCommonColors.white,
  },
  button: {
    main: IonColors.ionMidnightGreen[900],
    contrastText: IonCommonColors.white,
  },
};

export const lightTheme: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: IonColors.ionMidnightGreen[900],
      contrastText: IonCommonColors.white,
    },
    secondary: {
      main: IonColors.ionPastelRed[300],
      contrastText: IonCommonColors.black,
    },
    error: {
      main: IonColors.ionImerialRed[600],
      contrastText: IonCommonColors.white,
    },
    warning: {
      main: IonColors.ionGamboge[700],
      contrastText: IonCommonColors.white,
    },
    info: {
      main: IonColors.ionAzure[600],
      contrastText: IonCommonColors.white,
    },
    success: {
      main: IonColors.ionKellyGreen[500],
      contrastText: IonCommonColors.white,
    },
    background: {
      card: IonCommonColors.white,
      paper: "#F8F9FA",
      default: "#F8F9FA",
      tableHead: "#E7EBEE",
      dropdown: IonCommonColors.white,
    },
    text: {
      primary: IonColors.ionMaastrichtBlue[900],
    },
    grey: {
      ...IonColors.ionGrey,
    },
    ...customPalette,
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: "DM Sans, sans-serif",
    h1: {
      fontSize: "2em",
      fontWeight: 700,
    },
    h2: {
      fontSize: "1.5em",
      fontWeight: 700,
    },
    h3: {
      fontSize: "1.375em",
      fontWeight: 700,
    },
    h4: {
      fontSize: "1.25em",
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.125em",
      fontWeight: 700,
    },
    h6: {
      fontSize: "1em",
      fontWeight: 700,
    },
  },
  shadows: IonShadows,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          // "&.Mui-disabled": {
          //   background: IonColorPalette.disabled.main,
          //   color: IonColorPalette.disabled.contrastText,
          // },
        },
      },
    },
  },
};
