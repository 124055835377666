import { FC, memo } from "react";

import Loader from "@components/loader";
import { styled } from "@mui/material";

// Styled container
const LoaderContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
});

interface IonageNexusLoaderProps {
  size?: number;
}

const IonageNexusLoader: FC<IonageNexusLoaderProps> = memo(
  ({ size }) => {
    return (
      <LoaderContainer>
        <Loader size={size} />
      </LoaderContainer>
    );
  },
  () => true
);

export default IonageNexusLoader;
