import "./main.css";

import App from "./App.tsx";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

// import { datadogRum } from "@datadog/browser-rum";

const container = document.getElementById("root")!;
const root = createRoot(container);

const handlePreloadError = (event: VitePreloadErrorEvent, maxRetries = 1) => {
  const { payload } = event || {};
  const { message } = payload || {};
  console.error("Preload error encountered:", message);
  const retryCount = sessionStorage.getItem("retryCount");
  console.log("Retry attempt:", retryCount);
  if (retryCount) {
    const retries = parseInt(retryCount, 10);
    if (retries >= maxRetries) {
      console.warn("Maximum retry attempts reached. Aborting reload.");
      return;
    }
    console.info("Retrying preload. Attempt:", retries + 1);
    sessionStorage.setItem("retryCount", String(retries + 1));
  } else {
    console.info("First retry attempt.");
    sessionStorage.setItem("retryCount", "1");
  }
  setTimeout(() => window.location.reload(), 500);
};

const stopInstallPrompt = (event: Event) => {
  event.preventDefault();
};

window.addEventListener("vite:preloadError", (event) => {
  handlePreloadError(event, 3);
});

window.addEventListener("beforeinstallprompt", stopInstallPrompt);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

// datadogRum.init({
//   applicationId: "a72b8d3d-426f-401c-ad52-37b1b9ca9bd0",
//   clientToken: "pub888c02ab1c9d9cc9d4926c342433b9fc",
//   site: "us5.datadoghq.com",
//   service: "nexus-dashboard",
//   env: import.meta.env.VITE_ENV,
//   // Specify a version number to identify the deployed version of your application in Datadog
//   // version: '1.0.0',
//   sessionSampleRate: 100,
//   sessionReplaySampleRate: 20,
//   trackUserInteractions: true,
//   trackResources: true,
//   trackLongTasks: true,
//   defaultPrivacyLevel: "mask-user-input",
// });
